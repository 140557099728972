import React, { useContext } from "react";
import { Box, IconButton, useTheme, useMediaQuery } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit"; // Import Edit icon
import { FavoritesContext } from "../contexts/FavoritesContext";
import { Link } from "react-router-dom"; // Import Link

function QuickActions({ user, recipe, isFavorited, showActions }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { handleFavorite, handleAddToList, handleShare } = useContext(FavoritesContext);

  if (!showActions) return null;

  return (
    <Box
      className="quick-actions"
      sx={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: isMobile
          ? theme.palette.background.actions
          : "transparent",
        padding: isMobile ? theme.spacing(1) : "",
        boxShadow: isMobile ? theme.shadows[1] : "none",
        position: isMobile ? "absolute" : "static",
        top: isMobile ? 0 : "auto",
        right: isMobile ? 0 : "auto",
        height: isMobile ? "100%" : "auto",
        width: isMobile ? "208px" : "auto", // Adjusted width to accommodate the new icon

        display: showActions ? "flex" : "none",
        justifyContent: "space-around",
      }}
    >

      <IconButton
        className="quick-action-button"
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleFavorite(e, recipe.id);
        }}
        sx={!user ? { color: "action.disabled", opacity: 0.5 } : {}}
        aria-label={isFavorited ? "Unfavorite Recipe" : "Favorite Recipe"}
      >
        {isFavorited ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
      </IconButton>

      <IconButton
        className="quick-action-button"
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleAddToList(e, recipe.id);
        }}
        sx={!user ? { color: "action.disabled", opacity: 0.5 } : {}}
        aria-label="Add Recipe to List"
      >
        <PlaylistAddIcon />
      </IconButton>

      <IconButton
        className="quick-action-button"
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleShare(e, recipe.id);
        }}
        sx={{ color: "action.disabled", opacity: 0.5 }}
        aria-label="Share Recipe"
      >
        <ShareIcon />
      </IconButton>
      {/* Edit Icon - Only shown if the user owns the recipe */}
      {user && recipe.user_id === user.id && (
        <IconButton
          component={Link}
          to={`/recipes/edit/${recipe.id}`}
          className="quick-action-button"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
          }}
          aria-label="Edit Recipe"
        >
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default QuickActions;