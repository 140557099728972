import { Routes, Route } from 'react-router-dom';
import BookDetail from './BookDetail';
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import supabase from '../supabaseClient'; // Assuming you have the client setup
import { useTheme } from '@mui/material/styles';

function Books() {
  const [books, setBooks] = useState([]);
  const theme = useTheme(); // Access the theme

  // Fetch books from Supabase
  useEffect(() => {
    const fetchBooks = async () => {
      const { data, error } = await supabase.from('books').select('id, name, author');

      if (error) {
        console.error('Error fetching books:', error);
      } else {
        setBooks(data || []);
      }
    };

    fetchBooks();
  }, []);

  return (
    <Grid>
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <Typography
          variant="h5"
          sx={{ fontSize: '1.3rem', fontFamily: theme.typography.fontFamily, cursor: 'pointer' }}
        >
          books
        </Typography>
      </Box>
      <Box>
        {books.map((book) => (
          <Link
            key={book.id}
            to={`/books/${encodeURIComponent(book.name)}`}
            style={{ textDecoration: 'none' }}
          >
            <Box
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                padding: theme.spacing(2),
                margin: `${theme.spacing(2)} 0`,
                borderRadius: '.5em',
                cursor: 'pointer',
                transition: 'box-shadow 0.3s',
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                  boxShadow: theme.shadows[2],
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.primary,
                  fontSize: '1.1rem',
                  marginBottom: theme.spacing(0.5),
                }}
              >
                {book.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '.8rem',
                  color: theme.palette.text.secondary,
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                <em>{book.author ? `by ${book.author}` : 'various authors'}</em>
              </Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </Grid>
  );
}

function BooksPage({ user }) {
  return (
    <Routes>
      {/* Route for the list of books */}
      <Route path="/" element={<Books user={user} />} />

      {/* Dynamic route for individual book details */}
      <Route path="/:bookName" element={<BookDetail user={user} />} />
    </Routes>
  );
}

export default BooksPage;