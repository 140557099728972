import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import FavoriteRecipes from './FavoriteRecipes';
import supabase from '../supabaseClient';
import { Button, TextField, Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {IconButton} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

function ListOverview({ lists, onNewListCreated, user, onTogglePin }) {
  const [newListName, setNewListName] = useState('');
  const theme = useTheme();

  const handleCreateList = async () => {
    if (newListName.trim() === '') return;

    const { data, error } = await supabase
      .from('saved_lists')
      .insert([{ list_name: newListName, user_id: user.id }])
      .select();

    if (error) {
      console.error('Error creating new list:', error);
    } else if (data && data.length > 0) {
      setNewListName('');
      onNewListCreated(data[0]);
    }
  };

  return (
    <Grid>
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: '1.3rem',
            fontFamily: theme.typography.fontFamily,
            cursor: 'pointer',
          }}
        >
          lists
        </Typography>
      </Box>
      <Box>
        {lists.map((list) => (
          <Box
            key={list.list_id}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              padding: theme.spacing(2),
              margin: `${theme.spacing(2)} 0`,
              borderRadius: '.5em',
              cursor: 'pointer',
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: theme.shadows[2],
              },
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Link
                to={`/lists/${list.list_name}`}
                style={{ textDecoration: 'none', flexGrow: 1 }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.primary,
                    fontSize: '1.1rem',
                    marginBottom: theme.spacing(0.5),
                  }}
                >
                  {list.list_name}
                </Typography>
              </Link>
              {/* Hide the pin icon for 'favorites' list */}
              {list.list_name.toLowerCase() !== 'favorites' && (
                <IconButton color="primary"
                  onClick={() => onTogglePin(list.list_id, list.pinned)}
                >
                  {list.pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                </IconButton>
              )}
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          marginTop: theme.spacing(1),
        }}
      >
        <TextField
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          placeholder="new list name"
          variant="outlined"
          fullWidth
          sx={{
            fontFamily: theme.typography.fontFamily,
            '& .MuiOutlinedInput-root': {
              fontFamily: theme.typography.fontFamily,
              height: '3em',
            },
            '& .MuiOutlinedInput-input': {
              padding: theme.spacing(2),
            },
          }}
          InputProps={{
            style: {
              height: '3em',
            },
          }}
        />

        <Button
          variant="outlined"
          onClick={handleCreateList}
          sx={{
            // height: '3em',
            minWidth: '3em',
            boxShadow: 'none',
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          <AddIcon />
        </Button>
      </Box>
    </Grid>
  );
}

function ListsPage({ user }) {
  const [lists, setLists] = useState([]);

  // Sorting function based on your requirements
  const sortLists = (lists) => {
    return lists.sort((a, b) => {
      // 'favorites' list always first
      if (a.list_name.toLowerCase() === 'favorites') return -1;
      if (b.list_name.toLowerCase() === 'favorites') return 1;

      // Then pinned lists first
      if (a.pinned !== b.pinned) {
        return b.pinned - a.pinned;
      }

      // Then by created_at descending (most recent first)
      return new Date(b.created_at) - new Date(a.created_at);
    });
  };

  useEffect(() => {
    const fetchLists = async () => {
      const { data, error } = await supabase
        .from('saved_lists')
        .select('*')
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching lists:', error);
      } else {
        const sortedLists = sortLists(data || []);
        setLists(sortedLists);
      }
    };

    if (user) {
      fetchLists();
    }
  }, [user]);

  const handleTogglePin = async (listId, currentPinnedStatus) => {
    const { error } = await supabase
      .from('saved_lists')
      .update({ pinned: !currentPinnedStatus })
      .eq('list_id', listId);

    if (error) {
      console.error('Error updating pinned status:', error);
    } else {
      setLists((prevLists) => {
        const updatedLists = prevLists.map((list) =>
          list.list_id === listId ? { ...list, pinned: !currentPinnedStatus } : list
        );
        return sortLists(updatedLists);
      });
    }
  };

  const addNewList = (newList) => {
    setLists((prev) => {
      const updatedLists = [...prev, newList];
      return sortLists(updatedLists);
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ListOverview
            lists={lists}
            onNewListCreated={addNewList}
            user={user}
            onTogglePin={handleTogglePin}
          />
        }
      />
      {lists.map((list) => (
        <Route
          key={list.list_id}
          path={`/${list.list_name}`}
          element={
            <FavoriteRecipes
              user={user}
              listId={list.list_id}
              listName={list.list_name}
            />
          }
        />
      ))}
    </Routes>
  );
}

export default ListsPage;