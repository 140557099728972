import { useContext } from "react";
import { Skeleton, Alert, useTheme, useMediaQuery } from "@mui/material";
import RecipeDetail from "./RecipeDetail";
import RecipeLoadingSkeleton from "./Skeletons/RecipeLoadingSkeleton";
import { FavoritesContext } from "../contexts/FavoritesContext";

function RecipeList({
  loading,
  errorMessage,
  recipes,
  hasSearched,
  user,
  showResultsText,
  totalCount,
  onRecipeClick,
  disableExpansion,
  selectedRecipe, 
}) {
  const theme = useTheme();
  const { favoritedRecipes } = useContext(FavoritesContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (errorMessage) return <Alert severity="error">{errorMessage}</Alert>;

  if (loading) {
    return (
      <div>
        {/* Loading Skeleton */}
        <div
          style={{
            marginBottom: "1em",
            fontFamily: theme.typography.fontFamily,
            color: "rgb(170, 170, 170)",
            fontSize: ".8rem",
            marginLeft: "auto",
            textAlign: isMobile ? "right" : "left",
            transition: "opacity 0.5s ease",
          }}
        >
          <Skeleton
            variant="text"
            width={100}
            style={{ display: "inline-block" }}
          />
        </div>

        {/* Skeletons for Recipe Frames */}
        {Array.from({ length: 10 }).map((_, index) => (
          <RecipeLoadingSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (hasSearched && recipes.length === 0) {
    return (
      <Alert severity="error" style={{ marginTop: "1em" }}>
        No recipes found. Please try different filters or search terms.
      </Alert>
    );
  }

  if (!loading && recipes.length > 0) {
    return (
      <>
        {showResultsText && (
          <div
            style={{
              marginBottom: "1em",
              fontFamily: theme.typography.fontFamily,
              color: "rgb(170, 170, 170)",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "23px",
              textAlign: "right",
              transition: "opacity 0.5s ease",
            }}
          >
            {totalCount} recipes found
          </div>
        )}
        {recipes.map((recipe, index) => (
          <RecipeDetail
            key={recipe.id}
            recipe={recipe}
            index={index}
            isFavorited={favoritedRecipes.includes(recipe.id)}
            user={user}
            onRecipeClick={onRecipeClick}
            disableExpansion={disableExpansion}
            isSelected={selectedRecipe && recipe.id === selectedRecipe.id}
          />
        ))}
      </>
    );
  }

  return null; // Ensure the component returns something in all code paths
}

export default RecipeList;
