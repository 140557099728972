import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import supabase from "../supabaseClient";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  Paper,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import {
  Delete,
  ArrowUpward,
  ArrowDownward,
  // Edit as EditIcon,
} from "@mui/icons-material";
import isEqual from "lodash.isequal"; // Import isEqual function

function RecipeEditor({ user }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [initialRecipe, setInitialRecipe] = useState(null);
  const [loading, setLoading] = useState(true);

  // Form fields
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [newImageFile, setNewImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  // Loading state for image upload
  const [imageUploading, setImageUploading] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Track if changes have been made
  const [hasChanges, setHasChanges] = useState(false);

  // Validation errors
  const [ingredientErrors, setIngredientErrors] = useState([]);
  const [instructionErrors, setInstructionErrors] = useState([]);

  // Form submission state
  const [formSubmitted, setFormSubmitted] = useState(false);

  // State to track new additions
  const [newIngredientAdded, setNewIngredientAdded] = useState(false);
  const [newInstructionAdded, setNewInstructionAdded] = useState(false);

  // Refs for ingredients and instructions
  const ingredientNameRefs = useRef([]);
  const instructionRefs = useRef([]);

  useEffect(() => {
    // Fetch the recipe from Supabase
    const fetchRecipe = async () => {
      const { data, error } = await supabase
        .from("recipes")
        .select("*")
        .eq("id", id)
        .single();

      if (error) {
        console.error("Error fetching recipe:", error);
        return;
      }

      setInitialRecipe(data);

      setTitle(data.title || "");
      setDescription(data.description || "");

      // Initialize ingredients with isDeleted flag, touched state, and isNew
      const ingredientsWithFlags = (data.ingredients || []).map(
        (ingredient) => ({
          ...ingredient,
          isDeleted: false,
          touched: false,
          isNew: false,
        })
      );
      setIngredients(ingredientsWithFlags);

      // Initialize instructions with isDeleted flag, touched state, and isNew
      const instructionsWithFlags = (
        Array.isArray(data.instructions) ? data.instructions : []
      ).map((instruction) => ({
        text: instruction,
        isDeleted: false,
        touched: false,
        isNew: false,
      }));
      setInstructions(instructionsWithFlags);

      setImageUrl(data.image_url || "");
      setLoading(false);
    };

    fetchRecipe();
  }, [id]);

  // Helper functions to check if item is empty
  const isIngredientEmpty = (ingredient) => {
    return (
      (!ingredient.name || ingredient.name.trim() === "") &&
      (!ingredient.quantity.value ||
        ingredient.quantity.value.toString().trim() === "") &&
      (!ingredient.quantity.unit ||
        ingredient.quantity.unit.toString().trim() === "") &&
      (!ingredient.preparation || ingredient.preparation.trim() === "")
    );
  };

  const isInstructionEmpty = (instruction) => {
    return !instruction.text || instruction.text.trim() === "";
  };

  // Validation functions
  const validateIngredients = useCallback(() => {
    const errors = ingredients.map((ingredient) => {
      if (ingredient.isDeleted) return null; // Skip deleted ingredients
      const error = {};
      const hasName = ingredient.name && ingredient.name.trim() !== "";
      const hasQuantity =
        ingredient.quantity &&
        ingredient.quantity.value != null &&
        ingredient.quantity.value.toString().trim() !== "";

      if (!hasName) error.name = "required";
      if (!hasQuantity) error.quantity = "required";

      return Object.keys(error).length > 0 ? error : null;
    });
    setIngredientErrors(errors);
    return errors.every((error) => error === null);
  }, [ingredients]);

  const validateInstructions = useCallback(() => {
    const errors = instructions.map((instruction) => {
      if (instruction.isDeleted) return null;
      const error = {};
      const hasText = instruction.text && instruction.text.trim() !== "";
      if (!hasText) error.text = "instruction required";
      return Object.keys(error).length > 0 ? error : null;
    });
    setInstructionErrors(errors);
    return errors.every((error) => error === null);
  }, [instructions]);

  // Function to collect current form data into a single object
  const getCurrentFormData = useCallback(() => {
    return {
      title,
      description,
      ingredients: ingredients
        .filter((ing) => !ing.isDeleted)
        .map(({ isDeleted, id, isNew, touched, ...rest }) => rest),
      instructions: instructions
        .filter((instr) => !instr.isDeleted)
        .map(({ text }) => text), // Removed .sort() to preserve order
      image_url: newImageFile ? "new_image" : imageUrl,
    };
  }, [title, description, ingredients, instructions, imageUrl, newImageFile]);

  // Validation and change detection
  useEffect(() => {
    if (initialRecipe) {
      const currentData = getCurrentFormData();

      const initialData = {
        title: initialRecipe.title || "",
        description: initialRecipe.description || "",
        ingredients: (initialRecipe.ingredients || []).map(
          ({ id, ...rest }) => rest
        ),
        instructions: Array.isArray(initialRecipe.instructions)
          ? initialRecipe.instructions
          : [], // Removed .sort()
        image_url: initialRecipe.image_url || "",
      };

      // Compare current data with initial data
      const dataChanged = !isEqual(currentData, initialData);

      // Validate inputs
      const ingredientsValid = validateIngredients();
      const instructionsValid = validateInstructions();

      setHasChanges(dataChanged && ingredientsValid && instructionsValid);
    }
  }, [
    title,
    description,
    ingredients,
    instructions,
    imageUrl,
    initialRecipe,
    newImageFile,
    getCurrentFormData,
    validateIngredients,
    validateInstructions,
  ]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewImageFile(file);
      // Create a preview URL to display the new image before uploading
      setImagePreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    setFormSubmitted(true);

    const ingredientsValid = validateIngredients();
    const instructionsValid = validateInstructions();

    if (!ingredientsValid || !instructionsValid) {
      // Validation failed, do not proceed with save
      return;
    }

    setLoading(true);
    let updatedImageUrl = imageUrl;

    if (newImageFile) {
      setImageUploading(true);

      // Upload the new image to Supabase Storage
      const fileExt = newImageFile.name.split(".").pop();
      const fileName = `${id}-${Date.now()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      // Upload to 'recipe-images' bucket
      const { error: uploadError } = await supabase.storage
        .from("recipe-images")
        .upload(filePath, newImageFile);

      if (uploadError) {
        console.error("Error uploading image:", uploadError);
        setLoading(false);
        setImageUploading(false);
        return;
      }

      // Get the public URL for the image
      const { data: publicURLData } = supabase.storage
        .from("recipe-images")
        .getPublicUrl(filePath);

      updatedImageUrl = publicURLData.publicUrl;
      setImageUploading(false);
    }

    // Prepare data for saving
    const ingredientsToSave = ingredients
      .filter((ingredient) => !ingredient.isDeleted)
      .map(({ isDeleted, isNew, touched, ...rest }) => rest);

    const instructionsToSave = instructions
      .filter((instruction) => !instruction.isDeleted)
      .map((instruction) => instruction.text);

    // Update the recipe in Supabase
    const { error } = await supabase
      .from("recipes")
      .update({
        title,
        description,
        ingredients: ingredientsToSave,
        instructions: instructionsToSave,
        image_url: updatedImageUrl,
      })
      .eq("id", id);

    if (error) {
      console.error("Error updating recipe:", error);
    } else {
      // Show success notification
      setSnackbarOpen(true);

      // Update ingredients and instructions state to reflect deleted items and mark as existing
      setIngredients(
        ingredientsToSave.map((ingredient) => ({
          ...ingredient,
          isDeleted: false,
          touched: false,
          isNew: false,
        }))
      );

      setInstructions(
        instructionsToSave.map((text) => ({
          text,
          isDeleted: false,
          touched: false,
          isNew: false,
        }))
      );

      // After saving, update initialRecipe to the current data
      setInitialRecipe({
        title,
        description,
        ingredients: ingredientsToSave,
        instructions: instructionsToSave,
        image_url: updatedImageUrl,
      });

      // Reset form submission state
      setFormSubmitted(false);

      // Reset hasChanges to false
      setHasChanges(false);

      // Reset newImageFile if any
      if (newImageFile) {
        setNewImageFile(null);
        setImagePreviewUrl("");
      }
    }
    setLoading(false);
    navigate(-1)
  };

  // Ingredient handling functions
  const addIngredient = () => {
    setIngredients((prevIngredients) => {
      const newIngredient = {
        id: uuidv4(),
        name: "",
        quantity: { value: "", unit: "" },
        preparation: "",
        isDeleted: false,
        touched: false,
        isNew: true,
      };
      const updatedIngredients = [...prevIngredients, newIngredient];
      setNewIngredientAdded(true); // Set flag to focus the new ingredient
      return updatedIngredients;
    });
    setIngredientErrors((prevErrors) => [...prevErrors, null]); // Add placeholder for error
  };

  const updateIngredient = (index, field, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index][field] = value;
    setIngredients(newIngredients);
  };

  const updateIngredientQuantity = (index, field, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].quantity = {
      ...newIngredients[index].quantity,
      [field]: value,
    };
    setIngredients(newIngredients);
  };

  const handleIngredientBlur = (index) => {
    const newIngredients = [...ingredients];
    newIngredients[index].touched = true;
    setIngredients(newIngredients);
    validateIngredients(); // Update errors immediately on blur
  };

  const deleteIngredient = (index) => {
    const ingredient = ingredients[index];

    if (ingredient.isNew && isIngredientEmpty(ingredient)) {
      // Remove the ingredient immediately if it's new and empty
      const newIngredients = ingredients.filter((_, i) => i !== index);
      setIngredients(newIngredients);
      // Also remove the corresponding error entry
      const newErrors = ingredientErrors.filter((_, i) => i !== index);
      setIngredientErrors(newErrors);
    } else {
      // Otherwise, soft delete
      const newIngredients = [...ingredients];
      newIngredients[index].isDeleted = true;
      setIngredients(newIngredients);
    }
  };

  const restoreIngredient = (index) => {
    const newIngredients = [...ingredients];
    newIngredients[index].isDeleted = false;
    setIngredients(newIngredients);
  };

  // Instruction handling functions
  const addInstruction = () => {
    setInstructions((prevInstructions) => {
      const newInstruction = {
        text: "",
        isDeleted: false,
        touched: false,
        isNew: true,
      };
      const updatedInstructions = [...prevInstructions, newInstruction];
      setNewInstructionAdded(true); // Set flag to focus the new instruction
      return updatedInstructions;
    });
    setInstructionErrors((prevErrors) => [...prevErrors, null]); // Add placeholder for error
  };

  const updateInstruction = (index, value) => {
    const newInstructions = [...instructions];
    newInstructions[index].text = value;
    setInstructions(newInstructions);
  };

  const handleInstructionBlur = (index) => {
    const newInstructions = [...instructions];
    newInstructions[index].touched = true;
    setInstructions(newInstructions);
    validateInstructions(); // Update errors immediately on blur
  };

  const deleteInstruction = (index) => {
    const instruction = instructions[index];

    if (instruction.isNew && isInstructionEmpty(instruction)) {
      // Remove the instruction immediately if it's new and empty
      const newInstructions = instructions.filter((_, i) => i !== index);
      setInstructions(newInstructions);
      // Also remove the corresponding error entry
      const newErrors = instructionErrors.filter((_, i) => i !== index);
      setInstructionErrors(newErrors);
    } else {
      // Otherwise, soft delete
      const newInstructions = [...instructions];
      newInstructions[index].isDeleted = true;
      setInstructions(newInstructions);
    }
  };

  const restoreInstruction = (index) => {
    const newInstructions = [...instructions];
    newInstructions[index].isDeleted = false;
    setInstructions(newInstructions);
  };

  const moveInstructionUp = (index) => {
    if (index === 0) return; // Can't move up the first instruction
    const newInstructions = [...instructions];
    [newInstructions[index - 1], newInstructions[index]] = [
      newInstructions[index],
      newInstructions[index - 1],
    ];
    setInstructions(newInstructions);
  };

  const moveInstructionDown = (index) => {
    if (index === instructions.length - 1) return; // Can't move down the last instruction
    const newInstructions = [...instructions];
    [newInstructions[index + 1], newInstructions[index]] = [
      newInstructions[index],
      newInstructions[index + 1],
    ];
    setInstructions(newInstructions);
  };

  // Focus on newly added ingredient
  useEffect(() => {
    if (newIngredientAdded) {
      const lastIndex = ingredients.length - 1;
      const inputElement = ingredientNameRefs.current[lastIndex];
      if (inputElement) {
        inputElement.focus();
      }
      setNewIngredientAdded(false);
    }
  }, [ingredients, newIngredientAdded]);

  // Focus on newly added instruction
  useEffect(() => {
    if (newInstructionAdded) {
      const lastIndex = instructions.length - 1;
      const inputElement = instructionRefs.current[lastIndex];
      if (inputElement) {
        inputElement.focus();
      }
      setNewInstructionAdded(false);
    }
  }, [instructions, newInstructionAdded]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, maxWidth: "800px", margin: "0 auto" }}>
      <Typography
        variant="h5"
        sx={{
          fontSize: "1.3rem",
          fontFamily: theme.typography.fontFamily,
          cursor: "pointer",
          marginBottom: ".5em",
        }}
      >
        edit
      </Typography>
      <TextField
        label="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={3}
      />

      {/* Image Upload Section */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginTop: 3, marginBottom: 2 }}
      >
        image
      </Typography>
      <Box
        sx={{
          position: "relative",
          marginBottom: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imageUploading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {imageUrl || imagePreviewUrl ? (
          <Box sx={{ position: "relative", width: "100%", maxWidth: "400px" }}>
            <img
              src={imagePreviewUrl || imageUrl}
              alt="Recipe"
              style={{
                width: "100%",
                borderRadius: "8px",
                objectFit: "cover",
                maxHeight: "300px",
              }}
            />
            {/* Uncomment the code below if you want to allow editing the image */}
            {/* <IconButton
              color="secondary"
              component="label"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                border: `1px solid ${theme.palette.border.main}`,
                padding: ".25em",
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.actions
              }}
            >
              <EditIcon />
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleImageChange}
              />
            </IconButton> */}
            <IconButton
              color="secondary"
              onClick={() => {
                setImageUrl("");
                setNewImageFile(null);
                setImagePreviewUrl("");
              }}
              sx={{
                position: "absolute",
                bottom: 16,
                right: 8,
                border: `1px solid ${theme.palette.border.main}`,
                padding: ".25em",
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.actions,
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        ) : (
          <Button variant="outlined" component="label">
            upload image
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageChange}
            />
          </Button>
        )}
      </Box>

      {/* Ingredients Section */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginTop: 4, marginBottom: 2 }}
      >
        ingredients
      </Typography>

      {ingredients.map((ingredient, index) => {
        const error = ingredientErrors[index] || {};
        return (
          <Paper
            key={ingredient.id || index}
            sx={{
              padding: 2,
              marginBottom: 2,
              border: `1px solid ${theme.palette.border.main}`,
              opacity: ingredient.isDeleted ? 0.5 : 1,
              backgroundColor: ingredient.isDeleted
                ? theme.palette.background.actions
                : "inherit",
            }}
            elevation={0}
          >
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="name"
                  value={ingredient.name}
                  onChange={(e) =>
                    updateIngredient(index, "name", e.target.value)
                  }
                  fullWidth
                  disabled={ingredient.isDeleted}
                  onBlur={() => handleIngredientBlur(index)}
                  error={
                    (ingredient.touched || formSubmitted) && error && error.name
                  }
                  helperText={
                    (ingredient.touched || formSubmitted) && error && error.name
                  }
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "4px",
                      fontSize: "0.75rem", // Reduce font size
                    },
                  }}
                  inputRef={(el) => (ingredientNameRefs.current[index] = el)}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <TextField
                  label="quantity"
                  value={ingredient.quantity?.value || ""}
                  onChange={(e) =>
                    updateIngredientQuantity(index, "value", e.target.value)
                  }
                  fullWidth
                  disabled={ingredient.isDeleted}
                  onBlur={() => handleIngredientBlur(index)}
                  error={
                    (ingredient.touched || formSubmitted) &&
                    error &&
                    error.quantity
                  }
                  helperText={
                    (ingredient.touched || formSubmitted) &&
                    error &&
                    error.quantity
                  }
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "4px",
                      fontSize: "0.75rem", // Reduce font size
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <TextField
                  label="unit"
                  value={ingredient.quantity?.unit || ""}
                  onChange={(e) =>
                    updateIngredientQuantity(index, "unit", e.target.value)
                  }
                  fullWidth
                  disabled={ingredient.isDeleted}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="preparation"
                  value={ingredient.preparation || ""}
                  onChange={(e) =>
                    updateIngredient(index, "preparation", e.target.value)
                  }
                  fullWidth
                  multiline
                  disabled={ingredient.isDeleted}
                />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: "right" }}>
                {ingredient.isDeleted ? (
                  <Button
                    variant="text"
                    onClick={() => restoreIngredient(index)}
                  >
                    Restore
                  </Button>
                ) : (
                  <IconButton
                    color="secondary"
                    onClick={() => deleteIngredient(index)}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Paper>
        );
      })}

      <Button variant="outlined" onClick={addIngredient} sx={{ marginTop: 1 }}>
        add ingredient
      </Button>

      {/* Instructions Section */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginTop: 4, marginBottom: 2 }}
      >
        instructions
      </Typography>

      {instructions.map((instruction, index) => {
        const error = instructionErrors[index] || {};
        return (
          <Paper
            key={index}
            sx={{
              padding: 2,
              marginBottom: 2,
              border: `1px solid ${theme.palette.border.main}`,
              backgroundColor: instruction.isDeleted
                ? theme.palette.background.actions
                : "inherit",
            }}
            elevation={0}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  label={`Step ${index + 1}`}
                  value={instruction.text}
                  onChange={(e) => updateInstruction(index, e.target.value)}
                  fullWidth
                  multiline
                  disabled={instruction.isDeleted}
                  onBlur={() => handleInstructionBlur(index)}
                  error={
                    (instruction.touched || formSubmitted) &&
                    error &&
                    error.text
                  }
                  helperText={
                    (instruction.touched || formSubmitted) &&
                    error &&
                    error.text
                  }
                  inputRef={(el) => (instructionRefs.current[index] = el)}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                {instruction.isDeleted ? (
                  <Button
                    variant="text"
                    onClick={() => restoreInstruction(index)}
                  >
                    restore
                  </Button>
                ) : (
                  <>
                    <IconButton
                      onClick={() => moveInstructionUp(index)}
                      disabled={index === 0}
                    >
                      <ArrowUpward />
                    </IconButton>
                    <IconButton
                      onClick={() => moveInstructionDown(index)}
                      disabled={index === instructions.length - 1}
                    >
                      <ArrowDownward />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => deleteInstruction(index)}
                    >
                      <Delete />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        );
      })}

      <Button variant="outlined" onClick={addInstruction} sx={{ marginTop: 1 }}>
        add instruction
      </Button>

      {/* Save and Cancel Buttons */}
      <Box sx={{ marginTop: 4, textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading || !hasChanges}
        >
          save
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
          sx={{ marginLeft: 2 }}
        >
          cancel
        </Button>
      </Box>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="recipe saved"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </Box>
  );
}

export default RecipeEditor;
