import React, { useState, useEffect, useRef } from "react";
import supabase from "../supabaseClient";
import { Autocomplete, TextField, Paper, Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function DynamicFilter({ selectedFilters = [], updateFilters, user }) {
  const theme = useTheme();

  const [items, setItems] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filterType, setFilterType] = useState("Book");

  // Create a ref for the input element
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchItems = async () => {
      if (filterType === "Time") {
        setItems([]);
      } else if (filterType === "Owner") {
        if (user) {
          setItems([user]);
        } else {
          setItems([]);
        }
      } else {
        const { data, error } = await supabase
          .from("books")
          .select(filterType === "Book" ? "name" : "author");
        if (error) {
          console.error(`Error fetching ${filterType.toLowerCase()}s:`, error);
        } else {
          const mappedItems =
            filterType === "Book"
              ? [...new Set(data.map((item) => item.name))]
              : [
                  ...new Set(
                    data
                      .filter((item) => item.author !== null)
                      .map((item) => item.author)
                  ),
                ];
          setItems(mappedItems);
        }
      }
    };

    fetchItems();
  }, [filterType, user]);

  const filteredItems = items.filter((item) => {
    if (filterType === "Owner") {
      return !selectedFilters.some(
        (filter) => filter.type === filterType && filter.value === item.id
      );
    }
    return !selectedFilters.some(
      (filter) => filter.type === filterType && filter.value === item
    );
  });

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {filterType === "Time" ? (
        <TextField
          sx={{
            width: "60%",
            "& .MuiOutlinedInput-root": {
              fontFamily: theme.typography.fontFamily,
            },
            "& .MuiInputLabel-outlined": {
              fontFamily: theme.typography.fontFamily,
            },
          }}
          label="max time (minutes)"
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && inputValue && !isNaN(inputValue)) {
              updateFilters("Time", inputValue);
              setInputValue("");
              // Blur the input to close the virtual keyboard
              e.target.blur();
            }
          }}
          InputProps={{
            startAdornment: (
              <>
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  style={{
                    border: "none",
                    background: "transparent",
                    marginRight: theme.spacing(1),
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.primary,
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                >
                  <option value="Book">book</option>
                  <option value="Author">author</option>
                  {user && <option value="Owner">owner</option>}
                  <option value="Time">time</option>
                </select>
              </>
            ),
          }}
        />
      ) : (
        <Autocomplete
          sx={{ width: "60%" }}
          options={filteredItems}
          getOptionLabel={(option) => {
            if (filterType === "Owner") {
              return option.email;
            }
            return option;
          }}
          value={autocompleteValue}
          inputValue={inputValue}
          onChange={(event, newValue) => {
            if (filterType === "Owner") {
              updateFilters(filterType, newValue.id);
            } else {
              updateFilters(filterType, newValue);
            }
            setAutocompleteValue(null);
            setInputValue("");

            // Blur the input to close the virtual keyboard
            if (inputRef.current) {
              inputRef.current.blur();
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderOption={(props, option) => {
            if (filterType === "Owner") {
              return (
                <li {...props}>
                  <Avatar
                    src={option?.user_metadata?.avatar_url}
                    alt={option.email}
                    sx={{
                      width: 30,
                      height: 30,
                      marginRight: theme.spacing(1),
                    }}
                  />
                  {option.email}
                </li>
              );
            }
            return <li {...props}>{option}</li>;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                filterType === "Book"
                  ? "book title"
                  : filterType === "Author"
                  ? "author"
                  : filterType === "Owner"
                  ? "owner"
                  : ""
              }
              variant="outlined"
              fullWidth
              inputRef={inputRef} // Attach the ref here
              sx={{
                "& .MuiOutlinedInput-root": {
                  fontFamily: theme.typography.fontFamily,
                },
                "& .MuiInputLabel-outlined": {
                  fontFamily: theme.typography.fontFamily,
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <select
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                      style={{
                        border: "none",
                        background: "transparent",
                        marginRight: theme.spacing(2),
                        fontFamily: theme.typography.fontFamily,
                        color: theme.palette.text.primary,
                        fontSize: ".8rem",
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="Book">book</option>
                      <option value="Author">author</option>
                      <option value="Time">time</option>
                      {user && <option value="Owner">owner</option>}
                    </select>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                fontFamily: theme.typography.fontFamily,
                fontSize: "14px",
              }}
              elevation={0}
            >
              {children}
            </Paper>
          )}
        />
      )}
    </div>
  );
}

export default DynamicFilter;
