import React, { useState, useEffect, useMemo } from "react";
import SearchRecipes from "./Search/SearchRecipes";
import Header from "./Header";
import { ThemeProvider } from "@mui/material/styles";
import { Container, CssBaseline, CircularProgress, Box } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import supabase from "./supabaseClient";
import LoginPage from "./Login/Login.js";
import ListsPage from "./Lists/ListsPage";
import BooksPage from "./Books/BooksPage"; // Import the BooksPage component
import UploadRecipe from "./Upload/AdminUploadRecipe.js";
import RequireAuth from "./RequireAuth";
import PrivacyPolicy from "./PrivacyPolicy";
import { FavoritesProvider } from "./contexts/FavoritesContext.js";
import getTheme from "./theme";
import NotificationSnackbar from "./NotificationSnackbar.js";
import CommonDialogs from "./CommonDialogs.js";
import RecipeEditor from "./Recipes/RecipeEditor.js";
import UserUploadRecipe from "./Upload/UserUploadRecipe.js";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [themeMode, setThemeMode] = useState("dark"); // Theme mode state

  // Load theme mode from localStorage on initial render
  useEffect(() => {
    const savedTheme = localStorage.getItem("themeMode");
    if (savedTheme) {
      setThemeMode(savedTheme);
    }
  }, []);

  // Memoize the theme object based on the theme mode
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);

  // Function to toggle the theme mode
  const toggleTheme = () => {
    setThemeMode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("themeMode", newMode); // Save preference
      return newMode;
    });
  };

  // Update the <meta name="theme-color"> based on theme mode
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", theme.palette.background.default);
    }
  });

  // Authentication effect
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session?.user) {
        setUser(session.user);
      }
      setLoading(false); // Session check complete
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
      setLoading(false); // Session check complete
    });

    return () => subscription?.unsubscribe();
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  const allowedEmails = [
    "tim.silber@me.com",
    "testuser@reciply.xyz",
    "testuser@example.com",
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FavoritesProvider user={user}>
        <Router>
          <Header user={user} toggleTheme={toggleTheme} themeMode={themeMode} />
          <Container component="main" maxWidth={false} sx={{ px: 2, mt: 2 }}>
            <Box
              sx={{
                maxWidth: {
                  xs: "100%", // Full width on extra-small screens
                  sm: 600, // 600px max-width on small screens
                  md: 1300, // 800px max-width on medium screens and up
                },
                mx: "auto", // Center horizontally
              }}
            >
              <Routes>
                <Route path="/" element={<SearchRecipes user={user} />} />
                <Route path="/login" element={<LoginPage user={user} />} />

                {/* Lists route */}
                <Route
                  path="/lists/*"
                  element={
                    <RequireAuth user={user}>
                      <ListsPage user={user} />
                    </RequireAuth>
                  }
                />

                {/* Books routes - books list and individual book details */}
                <Route
                  path="/books/*"
                  element={
                    <RequireAuth user={user}>
                      <BooksPage user={user} />
                    </RequireAuth>
                  }
                />

                {/* Only your user account or the dev test user can access this route */}
                <Route
                  path="/upload"
                  element={
                    <RequireAuth user={user} allowedEmails={allowedEmails}>
                      <UploadRecipe user={user} />
                    </RequireAuth>
                  }
                />
                                <Route
                  path="/upload/user"
                  element={
                    <RequireAuth user={user} allowedEmails={allowedEmails}>
                      <UserUploadRecipe user={user} />
                    </RequireAuth>
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
    path="/recipes/edit/:id"
    element={
      <RequireAuth user={user}>
        <RecipeEditor user={user} />
      </RequireAuth>
    }
  />
              </Routes>
            </Box>
          </Container>
        </Router>
        <NotificationSnackbar />
        <CommonDialogs user={user} />
      </FavoritesProvider>
    </ThemeProvider>
  );
}

export default App;
