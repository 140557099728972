import { createTheme } from '@mui/material/styles';


// Define a function that takes themeMode as a parameter
const getTheme = (themeMode) =>
  createTheme({
    palette: {
      mode: themeMode,
      background: {
        default: themeMode === 'dark' ? '#121212' : '#FFFFFF',
        actions: themeMode === 'dark' ? '#171717' : '#f8f8f8', // Adjust paper background
        highlight: themeMode === 'dark' ? 'rgb(100,100,100,.05' : 'rgba(0, 0, 0, 0.04)' 
      },
      primary: {
        main: themeMode === 'dark' ? '#BB86FC' : 'rgba(0, 0, 0, 0.5)', // Adjust as per your branding
        light: themeMode === 'dark' ? '#C77CFF' : undefined,
        dark: themeMode === 'dark' ? '#3d216a' : 'rgba(0, 0, 0, 0.08)',
      },
      secondary: {
        main: themeMode === 'dark'
        ? 'rgba(255,255,255,0.30)'
        : 'rgb(137, 143, 151)',
      },
      error: {
        main: themeMode === 'dark' ? '#CF6679' : '#B00020',
      },
      border: {
        main:
          themeMode === 'dark'
            ? 'rgba(255,255,255,0.3)'
            : 'rgba(0,0,0,0.3)',
        highlight:themeMode === 'dark' ? '#3d216a' : 'rgba(0, 0, 0, 0.4)',
      },
      text: {
        primary:
          themeMode === 'dark'
            ? 'rgba(255,255,255,0.87)'
            : 'rgba(33,33,33,1)',
        secondary:
          themeMode === 'dark'
            ? 'rgba(255,255,255,0.30)'
            : 'rgb(137, 143, 151)',
        disabled:
          themeMode === 'dark'
            ? 'rgba(255,255,255,0.38)'
            : 'rgba(0,0,0,0.38)',
      },
      divider:
        themeMode === 'dark'
          ? 'rgba(255,255,255,0.2)'
          : 'rgba(0,0,0,0.2)',
    },
    shape:{
      borderRadius:'8px'
    },
    typography: {
      fontFamily: "'IBM Plex Mono', monospace",
    },
    components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none', // Disable uppercase transformation
            },
          }
        },
        MuiMenu: {
            styleOverrides: {
              paper: {
                boxShadow: themeMode === 'light'
                ? '0px 2px 4px rgba(0, 0, 0, 0.1)'
                : '0px 2px 4px rgba(0, 0, 0, 0.4)',
              },
            }
        },
        MuiPaginationItem: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                backgroundColor: themeMode === 'dark' ? '#BB86FC' : 'rgba(0, 0, 0, 0.1)', // Set background color
            color: themeMode === 'dark' ?  '#121212 ': '#121212',   // Set text color
            "&:hover": {
                backgroundColor: themeMode === 'dark' ? '#BB86FC' : 'rgba(0, 0, 0, 0.1)', // Set background color
            }, 
            }
          }
        }
    }
  }
  });

export default getTheme;