import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, Typography, Alert, CircularProgress, Grid } from '@mui/material';
import axios from 'axios';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import io from 'socket.io-client';
import supabase from '../supabaseClient'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const UploadRecipe = ({ user }) => {
    const [url, setUrl] = useState('');
    const [files, setFiles] = useState([]);
    const [recipeMessage, setRecipeMessage] = useState(null);
    const [recipeError, setRecipeError] = useState(null);
    const [bookMessage, setBookMessage] = useState(null);
    const [bookError, setBookError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [isBookUploadStarted, setIsBookUploadStarted] = useState(false);
    const statusRef = useRef(null);
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const SUMMARIZER_SERVER_URL = process.env.REACT_APP_SUMMARIZER_SERVER_URL;

    const socketRefs = useRef({});

    useEffect(() => {
      // Capture the current value of socketRefs.current
      const socketsToCleanUp = socketRefs.current;
    
      return () => {
        // Cleanup sockets on unmount
        Object.values(socketsToCleanUp).forEach(socket => {
          socket.disconnect();
        });
      };
    }, []);

    useEffect(() => {
        if (statusRef.current) {
            statusRef.current.scrollTop = statusRef.current.scrollHeight;
        }
    }, [status]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setRecipeMessage(null);
        setRecipeError(null);
        setIsLoading(true);

        try {
            const { data: sessionData } = await supabase.auth.getSession();
            const token = sessionData.session.access_token;
    
            // Send the request to the server
            const response = await axios.post(
                `${SERVER_URL}/api/uploads/scrape-url`,
                { url }, // Data to be sent in the body
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(response.data)

            const { task_id } = response.data;
            console.log('Task ID:', task_id);

            // Now set up a socket listener for this task_id
            const socket = io(SUMMARIZER_SERVER_URL);

            socket.on('connect', () => {
                console.log('Connected to server');

                const channel = `task_events:${task_id}`;

                socket.on(channel, (data) => {
                    console.log('Task Event:', data);
                    const { event, level, event_subtype } = data;

                    if (event_subtype === 'status_update') {
                        // Update status messages
                        setStatus(prevStatus => `${prevStatus.trim()}\n${event}`);
                    } else if (event_subtype === 'completed') {
                        setRecipeMessage('Recipe processing completed successfully.');
                        setRecipeError(null);
                        setIsLoading(false);
                        socket.disconnect();
                    } else if (level === 'error') {
                        setRecipeError(event || 'Recipe processing failed due to an error.');
                        setRecipeMessage(null);
                        setIsLoading(false);
                        socket.disconnect();
                    }
                });
            });

            // Store socket reference to disconnect later if needed
            socketRefs.current[task_id] = socket;

        } catch (err) {
            console.error('Error submitting URL:', err);
            setRecipeError(err.response?.data?.error || 'An error occurred');
            setIsLoading(false);
        }
    };

    const handleFileUpload = async (fieldName, file, metadata, load, error, progress, abort) => {
        setIsBookUploadStarted(true);
        setIsLoading(true);
        const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let start = 0;
        let parts = [];

        try {
            console.log('Starting upload for:', file.name);
            const { data: sessionData } = await supabase.auth.getSession();
            const token = sessionData.session.access_token;

            const { data: { uploadId } } = await axios.post(`${SERVER_URL}/api/uploads/start-upload`, {
                filename: file.name,
                fileType: file.type,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log('Upload ID:', uploadId);

            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunk = file.slice(start, end);

                console.log('Requesting presigned URL for chunk:', chunkIndex);
                const { data: { presignedUrl, partNumber } } = await axios.post(`${SERVER_URL}/api/uploads/get-presigned-url`, {
                    uploadId,
                    chunkIndex,
                    filename: file.name,
                    fileType: file.type,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                console.log('Presigned URL:', presignedUrl);

                const response = await axios.put(presignedUrl, chunk, {
                    headers: {
                        'Content-Type': file.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        progress(true, (chunkIndex * 100 / totalChunks) + (percentCompleted / totalChunks));
                    },
                });

                parts.push({ ETag: response.headers.etag, PartNumber: partNumber });
                start = end;
            }

            console.log('Completing upload with parts:', parts);
            const { data: completionData } = await axios.post(`${SERVER_URL}/api/uploads/complete-upload`, {
                uploadId,
                filename: file.name,
                parts,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            const { task_id } = completionData;
            console.log('Task ID:', task_id);

            load(file.name);
            setBookMessage('File uploaded successfully to S3. Processing started.');

            // Now set up a socket listener for this task_id

            const socket = io(SUMMARIZER_SERVER_URL);

            socket.on('connect', () => {
                console.log('Connected to server');

                const channel = `task_events:${task_id}`;

                socket.on(channel, (data) => {
                    console.log('Task Event:', data);
                    const { event, level, event_subtype } = data;

                    if (event_subtype === 'status_update') {
                        // Update status messages
                        setStatus(prevStatus => `${prevStatus.trim()}\n${event}`);
                    } else if (event_subtype === 'completed') {
                        setBookMessage('Ebook processing completed successfully.');
                        setBookError(null);
                        setIsLoading(false);
                        setIsBookUploadStarted(false);
                        socket.disconnect();
                    } else if (level === 'error') {
                        setBookError(event || 'Ebook processing failed due to an error.');
                        setBookMessage(null);
                        setIsLoading(false);
                        setIsBookUploadStarted(false);
                        socket.disconnect();
                    }
                });
            });

            // Store socket reference to disconnect later if needed
            socketRefs.current[task_id] = socket;

        } catch (err) {
            console.error('Error uploading file:', err);
            error('Upload failed');
            setBookError(err.response?.data?.error || 'An error occurred');
            setIsLoading(false);
            setIsBookUploadStarted(false);
            abort();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                {/* Upload Recipe Form */}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        upload recipe
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="url"
                        label="recipe URL"
                        name="url"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        sx={{ mt: 2 }}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Upload'}
                    </Button>
                    {recipeMessage && <Alert severity="success" sx={{ mt: 2 }}>{recipeMessage}</Alert>}
                    {recipeError && <Alert severity="error" sx={{ mt: 2 }}>{recipeError}</Alert>}
                </Box>

                {/* Upload Book Section */}
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        upload book
                    </Typography>
                    <div style={{ display: 'none' }}>
                        <style>
                            {`
                                .filepond--credits {
                                    display: none !important;
                                }
                            `}
                        </style>
                    </div>
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={true}
                        server={{
                            process: handleFileUpload,
                        }}
                        name="files" /* sets the file input name, it's filepond by default */
                        labelIdle="drag 'n drop files or <span class='filepond--label-action'>browse</span>"
                        acceptedFileTypes={['application/epub+zip', 'application/pdf']}

                                            />
                    {bookMessage && <Alert severity="success" sx={{ mt: 2 }}>{bookMessage}</Alert>}
                    {bookError && <Alert severity="error" sx={{ mt: 2 }}>{bookError}</Alert>}
                </Box>

                {/* Status Messages for Book Upload */}
                {isBookUploadStarted && (
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            status
                        </Typography>
                        <Box
                            ref={statusRef}
                            sx={{
                                p: 2,
                                maxHeight: 200,
                                overflow: 'auto',
                                whiteSpace: 'pre-wrap',
                                backgroundColor: '#f5f5f5',
                                border: '1px solid #ddd',
                                borderRadius: 2,
                            }}
                        >
                            {status.trim()}
                        </Box>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default UploadRecipe;