import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';

const UserUploadRecipe = ({ user }) => {
  const [url, setUrl] = useState('');
  const [recipeMessage, setRecipeMessage] = useState(null);
  const [recipeError, setRecipeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const navigate = useNavigate();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL; // Adjust if needed

  useEffect(() => {
    if (!taskId) return;

    const socket = io(SOCKET_SERVER_URL);

    socket.on('connect', () => {
      console.log('Connected to socket server');
    });

    socket.on('url_events', (data) => {
      console.log('URL Event:', data);
      const { event, level, event_subtype, task_id } = data;

      if (task_id === taskId) {
        if (event_subtype === 'completed') {
          setRecipeMessage('Recipe processing completed successfully.');
          setRecipeError(null);
          setIsLoading(false);

          // Fetch the scraped data using the task_id
          fetchScrapedData(taskId);
        } else if (level === 'error') {
          setRecipeError(event || 'Recipe processing failed due to an error.');
          setRecipeMessage(null);
          setIsLoading(false);
        }
      }
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });

    return () => {
      socket.disconnect();
    };
  }, );

  const fetchScrapedData = async (taskId) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/uploads/scraped-data/${taskId}`);
      const scrapedData = response.data;

      // Navigate to the RecipeEditor component with the scraped data
      navigate('/recipe-editor', { state: { initialData: scrapedData } });
    } catch (err) {
      console.error('Error fetching scraped data:', err);
      setRecipeError('Failed to retrieve scraped data.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRecipeMessage(null);
    setRecipeError(null);
    setIsLoading(true);

    try {
      // Include the user's access token if required
      const token = user?.accessToken; // Adjust as needed
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      // Send the request to the server
      const response = await axios.post(`${SERVER_URL}/api/uploads/scrape-url`, { url }, { headers });

      if (response.data.task_id) {
        const taskId = response.data.task_id;
        setTaskId(taskId);
        // Wait for socket events to know when processing is complete
      } else {
        // Handle error
        setRecipeError('Failed to start processing.');
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Error submitting URL:', err);
      setRecipeError(err.response?.data?.error || 'An error occurred');
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, maxWidth: '600px', mx: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Upload Recipe from URL
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="url"
        label="Recipe URL"
        name="url"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        required
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        sx={{ mt: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
      {recipeMessage && <Alert severity="success" sx={{ mt: 2 }}>{recipeMessage}</Alert>}
      {recipeError && <Alert severity="error" sx={{ mt: 2 }}>{recipeError}</Alert>}
    </Box>
  );
};

export default UserUploadRecipe;